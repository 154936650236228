<template>
    <div class="frame-content">
        <div class="frame-content-wrap">
            <div class="filter">
<!--                <div class="inline mr-8">-->
<!--                    <el-input v-model="filters.subcontractor" clearable placeholder="分包单位" size="small"></el-input>-->
<!--                </div>-->
                <div class="inline mr-8">
                    <el-select v-model="filters.supplierId" filterable clearable placeholder="分包单位" size="small" style="width: 300px;">
                        <el-option
                                v-for="item in supplierList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="inline ml-8">
                    <el-button type="primary" size="small" icon="el-icon-search" @click="loadTable">查询</el-button>
                </div>
            </div>
            <div class="datatable-wrap mt-10">
                <div class="topbar">
                    <div class="inline">
                        <el-button type="primary" size="small" @click="handleExport">导出Excel</el-button>
                    </div>
                    <!-- <div class="inline ml-8">
                        <el-button type="danger" size="small" @click="handleDeleteAll">删除</el-button>
                    </div> -->
                </div>
                <div class="datatable">
                    <el-table
                        ref="currentTable"
                        :data="tableData"
                        v-loading="tableLoading"
                        element-loading-background="rgba(255, 255, 255, 0.3)"
                        border
                        style="width: 100%">
                        <el-table-column
                            type="selection"
                            width="55"
                            align="center">
                        </el-table-column>
                        <el-table-column
                            label="序号"
                            align="center"
                            width="80">
                            <template slot-scope="scope">
                                <span>{{ (pageNum - 1) * pageSize + scope.$index + 1}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="projectName"
                            label="项目名称">
                        </el-table-column>
                        <el-table-column
                            prop="supplierName"
                            label="分包单位名称"
                            width="220">
                        </el-table-column>
                        <el-table-column
                            prop="revenue1"
                            align="center"
                            label="押金余额">
                        </el-table-column>
                        <el-table-column
                            prop="revenue0"
                            align="center"
                            label="租金余额">
                            <template slot-scope="scope">
                                <span>{{ scope.row.revenue0 - scope.row.paidMoney }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="action"
                            label="操作"
                            align="center"
                            width="200">
                            <template slot-scope="scope">
                                <el-button
                                    v-if="scope.row.payStatus !== 1"
                                    size="mini"
                                    type="primary"
                                    @click="handleAdd(scope.$index, scope.row)">资金划转</el-button>
                                <!-- <el-button
                                    v-if="scope.row.payStatus === 1"
                                    size="mini"
                                    type="primary"
                                    @click="handleExport(scope.$index, scope.row)">详情</el-button> -->
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>

            <el-dialog
                title="详情"
                v-if="detailDialog.create"
                :visible.sync="detailDialog.visible"
                :close-on-click-modal="false"
                @closed="handleDetailClosed"
                class="custom-dialog big-dialog"><!-- big-dialog small-dialog mini-dialog  -->
                <ConvertDetail :params="detailDialog.params"></ConvertDetail>
            </el-dialog>

            <el-dialog
                title="资金划拨"
                v-if="addDialog.create"
                :visible.sync="addDialog.visible"
                :close-on-click-modal="false"
                @closed="handleAddClosed"
                class="custom-dialog mini-dialog"><!-- big-dialog small-dialog mini-dialog  -->
                <ConvertAdd :params="addDialog.params" @done="handleAddDone"></ConvertAdd>
            </el-dialog>

            <el-dialog
                title="编辑"
                v-if="editDialog.create"
                :visible.sync="editDialog.visible"
                :close-on-click-modal="false"
                @closed="handleEditClosed"
                class="custom-dialog mini-dialog"><!-- big-dialog small-dialog mini-dialog  -->
                <ConvertEdit :params="editDialog.params" @done="handleEditDone"></ConvertEdit>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import common from '@/utils/common'
import http from '@/utils/http'
import ConvertDetail from './ConvertDetail'
import ConvertAdd from './ConvertAdd'
import ConvertEdit from './ConvertEdit'

export default {
    name: 'Convert',
    components: {
        ConvertDetail,
        ConvertAdd,
        ConvertEdit
    },
    data() {
        return {
            filters: {
                supplierId: '',
                projectId: '',
            },
            supplierList: [],
            tableData: [],

            totalNum: 0,
            pageNum: 1,
            pageSize: 50,

            tableLoading: false,

            detailDialog: {
                create: false,
                visible: false,
                params: {},
            },

            addDialog: {
                create: false,
                visible: false,
            },

            editDialog: {
                create: false,
                visible: false,
                params: {},
            },
        };
    },
    created () {
        this.loadTable();
        this.loadSupperList();
    },
    methods: {
        ...common,
        loadSupperList() {
            http.get("v1/supplier/list").then(req => {
                if (req.data.code == http.SUCCESS) {
                    this.supplierList = req.data.data;
                }
                else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.httpCatch(err);
            });
        },
        // 加载表格数据
        loadTable() {
            let params = {...this.filters};

            // params.pageNum = this.pageNum;
            // params.pageSize = this.pageSize;

            this.tableLoading = true;
            http.get('v1/payment/supplierPaymentList', {
                params: params
            }).then(req => {
                this.tableLoading = false;
                if (req.data.code == http.SUCCESS) {
                    this.tableData = req.data.data;
                    //this.totalNum = req.data.totalCount;
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.tableLoading = false;
                this.httpCatch(err);
            });
        },

        // 详情弹窗口
        handleDetail(index, row) {
            this.detailDialog = { create: true, params: row };
            this.$nextTick(() => {
                this.detailDialog = { ...this.detailDialog, visible: true };
            });
        },
        handleDetailClosed() {
            this.detailDialog = { create: false, visible: false, params: {} };
        },

        // 添加弹窗口
        handleAdd(index, row) {
            this.addDialog = { create: true, visible: false, params: row };
            this.$nextTick(() => {
                this.addDialog = { create: true, visible: true, params: row };
            });
        },
        handleAddDone() {
            this.addDialog = { create: true,  visible: false, params: {} };
            this.loadTable();
        },
        handleAddClosed() {
            this.addDialog = { create: false, visible: false, params: {} };
        },

        // 编辑弹窗口
        handleEdit(index, row) {
            this.editDialog = { create: true, visible: false, params: row };
            this.$nextTick(() => {
                this.editDialog = {  create: true, visible: true, params: row };
            });
        },
        handleEditDone() {
            this.editDialog = { create: true, visible: false, params: {} };
            this.loadTable();
        },
        handleEditClosed() {
            this.editDialog = { create: false, visible: false, params: {} };
        },

        handleDisable(index, row, status) {
            http.put(http.MOCK_Rent_API_URL + 'demo/disable', { id: row.id, status: status }).then(req => {
                if (req.data.code == http.SUCCESS) {
                    this.loadTable();
                    this.showSuccess('禁用成功！');
                } else {
                    this.showError(req.data.msg);
                }
            });
        },
        handleDelete(index, row) {
            this.confirm('确认删除？', function () {
                http.delete(http.MOCK_Rent_API_URL + 'demo/' + row.id).then(req => {
                    if (req.data.code == http.SUCCESS) {
                        this.loadTable();
                        this.showSuccess('删除成功！');
                    } else {
                        this.showError(req.data.msg);
                    }
                });
            });
        },
        handleDeleteAll() {
            if (this.$refs.currentTable.selection.length == 0) {
                this.showError('请选择要删除的信息！');
            } else {
                this.confirm('确认删除所选信息？', function () {
                    let idarr = [];
                    for (let i = 0; i < this.$refs.currentTable.selection.length; i++) {
                        idarr.push(this.$refs.currentTable.selection[i].id);
                    }
                    http.delete(http.MOCK_Rent_API_URL + 'demo', { data: idarr }).then(req => {
                        if (req.data.code == http.SUCCESS) {
                            this.loadTable();
                            this.showSuccess('删除成功！');
                        } else {
                            this.showError(req.data.msg);
                        }
                    });
                });
            }
        },
        handleExport() {}
    }
}
</script>

<style scoped>
</style>
