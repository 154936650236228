<template>
    <div class="dialog-inner" style="padding-top:0;">
        <el-form ref="form" :model="form" :rules="rules" label-width="100px" :show-message="true" class="form-col2">
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="项目名称：" class="mix-item">
                        {{ params.projectName }}
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="分包单位名称：" class="mix-item">
                        {{ params.supplierName }}
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="划拨类型" prop="type">
                        <el-select v-model="form.type" placeholder="划拨类型" size="small" style="width:100%;">
                            <el-option label="押金转租金" :value="1"></el-option>
                            <el-option label="租金转押金" :value="0"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="金额" prop="payin">
                        <el-input type="text" v-model="form.payin" size="small" maxlength="50" @blur="fieldTrim(form, 'name')"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20" class="submit-row">
                <el-col :span="24">
                    <el-form-item>
                        <el-button type="primary" @click="handleSubmit" :loading="submitting" size="small">提交</el-button>
                        <el-button @click="resetForm" size="small">重置</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import http from "@/utils/http"
import common from "@/utils/common"
import verify from "@/utils/verify"
import BigDecimal from 'js-big-decimal'

export default {
    name: 'ConvertAdd',
    props: {
        params: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
            paymentInfo: {
                rent: '-',
            },
            form: {
                supplierId: this.params.supplierId,
                projectId: this.params.projectId, 
                type: 1,
                payin: '',
            },
            rules: {
                type: [{required: true, message: '请选择划拨类型', trigger: 'change'}],
                payin: [
                    { required: true, message: '金额不能为空', trigger: ['blur','input'] },
                    { required: true, validator: this.checkMoney, trigger: ['blur','input'] },
                    { required: true, validator: this.checkSurplus, trigger: ['blur','input'] },
                ],
            },
            surplusDeposit: '-',
            surplusRent: '-',
            submitting: false
        }
    },
    created() {
        this.surplusDeposit = new BigDecimal(this.params.revenue1);
        this.surplusRent = new BigDecimal(this.params.revenue0 - this.params.paidMoney);
        this.loadPaymentInfo();
    },
    methods: {
        ...common,
        ...verify,
        loadPaymentInfo() {
            let params = {};
            params.id = this.params.supplierId;
            http.get("v1/payment/fund", { params: params }).then(req => {
                if (req.data.code == http.SUCCESS) {
                    this.paymentInfo = req.data.data;
                }
                else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.httpCatch(err);
            });
        },
        handleSubmit(){
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.submitting = true;
                    http.post('v1/payment/change', this.form).then(req => {
                        this.submitting = false;
                        if (req.data.code == http.SUCCESS) {
                            this.showSuccess('添加成功', () => {
                                this.$emit('done');
                            });
                        } else {
                            this.showError(req.data.msg);
                        }
                    }).catch(err => {
                        this.submitting = false;
                        this.httpCatch(err);
                    });
                } else {
                    return false;
                }
            });
        },
        checkSurplus(rule, value, callback) {
            let payin = new BigDecimal(this.form.payin);

            if (this.form.type == 1) {  // 押金转租金
                if (this.paymentInfo.rent == '-') {
                    callback(new Error('未获取到支付数据'));
                }

                let rent = new BigDecimal(this.paymentInfo.rent);
                let two = new BigDecimal("2");

                let d = this.surplusDeposit.subtract(payin);
                let twoMonthRent = rent.multiply(two);
                if (twoMonthRent.compareTo(d) > 0) {
                    callback(new Error('押金余额不足以支付2个月的租金'));
                }
            } else if (this.form.type == 0) {  // 租金转押金
                if (this.surplusRent.compareTo(payin) < 0) {
                    callback(new Error('资金划拨金额大于租金余额'));
                }
            }

            callback();
        },
    }
};
</script>

<style scoped>
</style>